.ads-badge-container {  
  display: flex;
  justify-content: space-around;
  align-items: flex-end;   
  padding: 6px 6px;  
  z-index: 9999999;
}
.banner-label {
  margin: 0;
  font-size: 19px;
  font-weight: normal;
  color: white;
  padding: 6px;
}