@font-face {
  font-family: "cronos_semibold";
  src: url("/fonts/cronos_semibold.eot?#iefix");
  src: url("/fonts/cronos_semiboldc.eot?#iefix") format("eot"), url("/fonts/cronos_semibold.woff") format("woff"), url("/fonts/cronos_semibold.ttf") format("truetype"), url("/fonts/cronos_semibold.svg#cronos_semibold") format("svg");
}

/* cairo-regular - latin */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/cairo-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/cairo-v18-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/cairo-v18-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/cairo-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/cairo-v18-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/cairo-v18-latin-regular.svg#Cairo") format("svg"); /* Legacy iOS */
}

/* cairo-700 - latin */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/cairo-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/cairo-v18-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/cairo-v18-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/cairo-v18-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/cairo-v18-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/cairo-v18-latin-700.svg#Cairo") format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/montserrat-v24-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/montserrat-v24-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/montserrat-v24-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/montserrat-v24-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/montserrat-v24-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/montserrat-v24-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/montserrat-v24-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/montserrat-v24-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/montserrat-v24-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/montserrat-v24-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/montserrat-v24-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/montserrat-v24-latin-700.svg#Montserrat") format("svg"); /* Legacy iOS */
}

body,
html {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fs-bg {
  background-color: #eeeeee;
}

.font-cairo {
  font-family: "Cairo", sans-serif;
}

.font-cronos {
  font-family: cronos_semibold, sans-serif;
}

.app {
  min-width: 300px;
  width: 100vw;
  height: 68.44vw; /* height:width ratio = 10.95/16 = .684375  */
  max-height: 100vh;
  max-width: 146vh; /* 16/10.95 = 1.6 */
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  /* vertical center */
  left: 0;
  right: 0;
  /* horizontal center */
}

.app.mode-fs {
  /*
   * max-width: 100%;
  max-height: 100vh;
*/
}

.app-content {
  border-radius: 4px;
  background-color: #3b3c41;
  position: relative;
}

.footer {
  font-size: 10px;
  text-align: right;
  padding: 5px;
  margin: 0;
}

.footer a {
  text-decoration: none;
  color: #595959;
}

.d-none {
  display: none !important;
}

.dark .resort-title,
.dark svg .dark body {
}
.dark svg circle {
  stroke: #595959;
}
.dark svg text {
  fill: #595959;
}
.dark svg path {
  fill: #595959;
}
