.player-tool-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
}

.player-tool-bar > ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  flex-wrap: wrap;
}

.player-tool-bar li {
  list-style: none;
}

.player-tool-bar .player__icon {
  display: block;
  margin: 0 4px;
  border-radius: 50%;
  border: solid 2px transparent;
  width: 26px;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.player-tool-bar .player__icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.player__progress-wrapper {
  margin-left: 30px;
}

.progress-bar {
  position: relative;
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
}

.progress-bar-bar {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}

.progress-bar input {
  position: absolute;
  top: 0;
  left: -2px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.player__time {
  margin-left: 15px;
  font-size: 12px;
  width: 95px;
  color: white;
}

.player__subscribe {
  margin-bottom: -3px;
}
.player-tool-bar {
  z-index: 9999999;
}
@media screen and (max-width: 540px) {
  .player-tool-bar .player__progress-wrapper,
  .player-tool-bar .player__social {
    display: none;
  }
}

@media screen and (max-width: 820px) {
}

.social-overlay {
  position: absolute;
  width: 111px;
  height: 178px;
  opacity: 0;
  bottom: 0;
  right: 0;
}

.switch_label {
  font-size: 10px;
  float: right;
  color: #fff;
  display: flex;
  margin-top: 4px;
  cursor: pointer;
}

.rowLi {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-between;
  margin-left: 40px;
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .player-tool-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px 0px 6px;
    position: absolute;
    top: -40px;
    left: 0;
    width: 50%;
    box-sizing: border-box;
  }
  .player__progress-wrapper,
  .progress-bar,
  .player__mobile-hide {
    display: none !important;
  }

  .player-tool-bar .player__icon {
    margin: 0 2px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  .player__time {
    margin-left: 0px;
    width: auto;
    font-size: 9px;
  }

  .player__controls .rowLi {
    width: 100px;
    margin-left: 10px;
  }

  .switch_label {
    font-size: 9px;
  }

  .menu__block {
    top: 46px !important;
  }
}
