.player-info-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  padding: 6px 15px;
  background-color: rgba(59,60,65,0.5);
}

.piste__info {
  display: flex;
  align-items: center;
}

.piste__info .piste__badge {
  font-size: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 0 2px #fff;
  margin-right: 8px;
}



.piste__info .piste__name {
  color: white;
}

.piste__info .piste__badge-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  display: inline-block;
  margin-right: 8px;
  text-align: center;
}

.piste__info .piste__badge-text {
  font-size: 13px;
  line-height: 24px;
  color: white;
}

.piste__attrs {
  margin: 0;
}

.piste__attrs .item {
  display: inline-block;
  padding-left: 15px;
  list-style: none;
}

.piste__attrs .icon {
  margin-right: 5px;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
}

.piste__attrs .count {
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 540px) {
  .piste__info .piste__name {
    display: none;
  }
}