.player__pushoverlay {
  z-index: 99999991;
  cursor: normal;
  text-transform: uppercase;
  margin-bottom: -50px;
  margin-left: -50px;
}

.player__pushoverlay.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.player__pushoverlay.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.player__pushoverlay.player__pushoverlay--near {
  margin-bottom: -75px;
  margin-left: -75px;
}

.player__pushoverlay .push__label {
	color: #A9A9A9;
	
}
