.popup-menu {
  z-index: 9999999;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 15px;
  background-color: rgba(59, 60, 65, .8);
}

.popup-menu.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

.popup-menu.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

.popup-menu::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(59,60,65,0.8);
  content: '';
  position: absolute;
  bottom: -6px;
  right: 15px;
}

.popup-menu > ul {
  padding-left: 0;
  margin: 0;
}

.popup-menu__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
  letter-spacing: 0.02em;
  color: white;
}

.popup-menu__item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.popup-menu__item:not(:last-of-type) {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.popup-menu__label {
  color: white;
}

.popup-menu__options {
  padding: 0;
  margin-left: 15px;
}

.popup-menu__options li {
  display: inline-block;
  margin-left: 5px;
}

.popup-menu__options .label {
  padding: 0 4px;
  border-radius: 3px;
  color: rgba(255, 255, 255, .5);
  cursor: pointer;
}

.popup-menu__options .label.active {
  color: white;
  background-color: rgba(255, 255, 255, .5);
}

.popup-menu__options .label:hover {
  background-color: rgba(255, 255, 255, .4);
}

.show {
  opacity: 1;
  transition: .5s;
}

.fade {
  opacity: 0;
  transition: .5s;
}
