.player__overlay {
  z-index: 99999991;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: -50px;
  margin-left: -50px;
}

.player__overlay.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.player__overlay.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.player__overlay.player__overlay--near {
  margin-bottom: -75px;
  margin-left: -75px;
}

.pv-icon-holder {
  position: absolute;
  cursor: pointer;
  z-index: 99999991;
  margin-bottom: -60px;
  margin-left: -100px;
}
.pv-icon-holder.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.pv-icon-holder.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.pv-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 50px;
  width: auto;
  border-radius: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
  color: rgba(228, 34, 34, 0.5);
  margin-bottom: 25px;
}
.pv-icon--dark {
  background-color: rgba(0, 191, 255, 0.5);
  color: white;
}
.pv-icon--dark .pv-icon__symbol {
  box-shadow: 0 0 0 2px #fff;
  background-color: rgba(228, 34, 34, 1);
}
.pv-icon--dark .pv-icon__symbol--v2 {
  background-color: rgba(0, 191, 255, 1);
}
.pv-icon--dark .pv-icon__label {
  color: white;
}
.pv-icon__symbol {
  font-size: 14px;
  color: white;
  border-radius: 50%;
  margin: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: rgba(228, 34, 34, 0.5);
}
.pv-icon__symbol--v2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 191, 255, 0.5);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABlCAYAAABUfC3PAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtVJREFUeNrsm/9R4kAUgFfH/00HhxWAFZirQEqQCg47wAq0gwsdYAXGDkIHuQ6ggpjVZXzgJnPHbdyX8H0zb3TYcQ378fY3Z1VVGdDFOU2AFEAKUgApSAGkIAWQAkhBCiAFKYAUpABSAClIAaQgBZCCFLAkdUzrSGM+xAUS3gXsYizKZnVkMR7q7MTufbVJkDy7jCFTIkp4rSMXEZULJMSXMDQpg5DQdymDlNA3KX8rwbJ0s6W859mvbvZ1TCa8uNd+DkGIhkw5ie5IuxQkKJLyhARdUuZ1/EKCLikrlyUFEvRIKU3EfaQ+wtY9UgAprOijsXsDazfT22EnFhukxMHO5m48r6/drK93YobQfU3duucQMgX6tU4JRWrC3DIp3YJ2Q6Ycz50bwMcB69w6MXONcrRLsY322GH9KicD58oz5FF8sq/th+gf4tr93Y6ZKFu618YuY5RN8utMURijOjbVB/bn5Mh6JtUntp5ElGWibKHp/WuVkosGm/5nXYuGxreCClE2QkpzpKKhskB1lg3ZIjMp19IGGseUhRhH5oHrvDT7xwiFGF/srsCIgf4rI7Fl8hRwVrQSg/60QZgJ+CEYlBTZYFnAejfm88Tz1rOYfG4QhhTRKGvXWCFZHewO+Mp+1DFByj43ngYMRdEiJRe/I+VgPPE1YBdSEk8XtvU8B1IOxoAu2LZkQ0GmtDPpuM7EU560lJ3shqTt518UPMerifxFVC5O+KfPdF9isNVAgRR9UkqkfO3PyRRlUmIfOP1Bij4pKk4hNZ7R58Z/ue47uGJM8bOIOJ6VZIqubLnSIkXr4vHO7N9E6ZoHRVNy1fe+rJjf39RtpZreuOZtlqyO+47/hz1MU/fVP+17X/acfubWDyGxXePSKP2qRJ9u3aeGC95A9wVIQQogBSmAFKQAUgApSAGkIAWQghRACiAFKYAUpABSkAJIAaQgBZCCFAjFmwADAPvHVjSjrBSpAAAAAElFTkSuQmCC");
  transition: background-color 0.5s ease-in-out;
}
.pv-icon__label {
  margin-right: 8px;
  margin-left: 8px;
  width: auto;
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  word-break: keep-all;
  white-space: nowrap;
}
.pv-icon__label--v2 {
  color: rgba(0, 191, 255, 0.5);
}

@media screen and (max-width: 540px) {
  .pv-icon {
    height: 40px;
    margin-bottom: 20px;
  }
  .pv-icon__symbol {
    font-size: 14px;
    margin: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pv-icon__label {
    margin-right: 6px;
    margin-left: 6px;
    line-height: 14px;
    font-size: 12px;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .pv-icon {
    height: 40px;
    margin-bottom: 20px;
  }
  .pv-icon__symbol {
    font-size: 14px;
    margin: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pv-icon__label {
    margin-right: 6px;
    margin-left: 6px;
    line-height: 14px;
    font-size: 12px;
  }
}