.map {
    width: 100%;
    height: 620px;
    position: absolute;
    top: 63px;
    background-color: #3B3C41;
    z-index: 99999999;
}

@media screen and (max-width: 540px) {
    .map {
        height: auto;
        position: fixed;
        top: 57px;
        bottom: 0;
    }
}