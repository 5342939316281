body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.player-info-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  padding: 6px 15px;
  background-color: rgba(59,60,65,0.5);
}

.piste__info {
  display: flex;
  align-items: center;
}

.piste__info .piste__badge {
  font-size: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 0 2px #fff;
  margin-right: 8px;
}



.piste__info .piste__name {
  color: white;
}

.piste__info .piste__badge-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  display: inline-block;
  margin-right: 8px;
  text-align: center;
}

.piste__info .piste__badge-text {
  font-size: 13px;
  line-height: 24px;
  color: white;
}

.piste__attrs {
  margin: 0;
}

.piste__attrs .item {
  display: inline-block;
  padding-left: 15px;
  list-style: none;
}

.piste__attrs .icon {
  margin-right: 5px;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
}

.piste__attrs .count {
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 540px) {
  .piste__info .piste__name {
    display: none;
  }
}
.player-tool-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
}

.player-tool-bar > ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  flex-wrap: wrap;
}

.player-tool-bar li {
  list-style: none;
}

.player-tool-bar .player__icon {
  display: block;
  margin: 0 4px;
  border-radius: 50%;
  border: solid 2px transparent;
  width: 26px;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.player-tool-bar .player__icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.player__progress-wrapper {
  margin-left: 30px;
}

.progress-bar {
  position: relative;
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
}

.progress-bar-bar {
  background-color: white;
  border-radius: 4px;
  height: 100%;
}

.progress-bar input {
  position: absolute;
  top: 0;
  left: -2px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.player__time {
  margin-left: 15px;
  font-size: 12px;
  width: 95px;
  color: white;
}

.player__subscribe {
  margin-bottom: -3px;
}
.player-tool-bar {
  z-index: 9999999;
}
@media screen and (max-width: 540px) {
  .player-tool-bar .player__progress-wrapper,
  .player-tool-bar .player__social {
    display: none;
  }
}

@media screen and (max-width: 820px) {
}

.social-overlay {
  position: absolute;
  width: 111px;
  height: 178px;
  opacity: 0;
  bottom: 0;
  right: 0;
}

.switch_label {
  font-size: 10px;
  float: right;
  color: #fff;
  display: flex;
  margin-top: 4px;
  cursor: pointer;
}

.rowLi {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-between;
  margin-left: 40px;
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .player-tool-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px 0px 6px;
    position: absolute;
    top: -40px;
    left: 0;
    width: 50%;
    box-sizing: border-box;
  }
  .player__progress-wrapper,
  .progress-bar,
  .player__mobile-hide {
    display: none !important;
  }

  .player-tool-bar .player__icon {
    margin: 0 2px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  .player__time {
    margin-left: 0px;
    width: auto;
    font-size: 9px;
  }

  .player__controls .rowLi {
    width: 100px;
    margin-left: 10px;
  }

  .switch_label {
    font-size: 9px;
  }

  .menu__block {
    top: 46px !important;
  }
}

.popup-menu {
  z-index: 9999999;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 15px;
  background-color: rgba(59, 60, 65, .8);
}

.popup-menu.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

.popup-menu.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

.popup-menu::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(59,60,65,0.8);
  content: '';
  position: absolute;
  bottom: -6px;
  right: 15px;
}

.popup-menu > ul {
  padding-left: 0;
  margin: 0;
}

.popup-menu__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
  letter-spacing: 0.02em;
  color: white;
}

.popup-menu__item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.popup-menu__item:not(:last-of-type) {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.popup-menu__label {
  color: white;
}

.popup-menu__options {
  padding: 0;
  margin-left: 15px;
}

.popup-menu__options li {
  display: inline-block;
  margin-left: 5px;
}

.popup-menu__options .label {
  padding: 0 4px;
  border-radius: 3px;
  color: rgba(255, 255, 255, .5);
  cursor: pointer;
}

.popup-menu__options .label.active {
  color: white;
  background-color: rgba(255, 255, 255, .5);
}

.popup-menu__options .label:hover {
  background-color: rgba(255, 255, 255, .4);
}

.show {
  opacity: 1;
  transition: .5s;
}

.fade {
  opacity: 0;
  transition: .5s;
}

.popup-menu.social-share {
  position: absolute;
  bottom: 50px;
  right: 12px;
}
.popup-menu.social-share.fade {
  visibility: hidden;
}

.popup-menu__link {
  text-decoration: none;
  color: rgba(255, 255, 255, .5)
}

.popup-menu__link:hover {
  color: white;
}

.popup-menu__link i {
  margin-right: 15px;
}
.player__overlay {
  z-index: 99999991;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: -50px;
  margin-left: -50px;
}

.player__overlay.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.player__overlay.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.player__overlay.player__overlay--near {
  margin-bottom: -75px;
  margin-left: -75px;
}

.pv-icon-holder {
  position: absolute;
  cursor: pointer;
  z-index: 99999991;
  margin-bottom: -60px;
  margin-left: -100px;
}
.pv-icon-holder.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.pv-icon-holder.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.pv-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 50px;
  width: auto;
  border-radius: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
  color: rgba(228, 34, 34, 0.5);
  margin-bottom: 25px;
}
.pv-icon--dark {
  background-color: rgba(0, 191, 255, 0.5);
  color: white;
}
.pv-icon--dark .pv-icon__symbol {
  box-shadow: 0 0 0 2px #fff;
  background-color: rgba(228, 34, 34, 1);
}
.pv-icon--dark .pv-icon__symbol--v2 {
  background-color: rgba(0, 191, 255, 1);
}
.pv-icon--dark .pv-icon__label {
  color: white;
}
.pv-icon__symbol {
  font-size: 14px;
  color: white;
  border-radius: 50%;
  margin: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: rgba(228, 34, 34, 0.5);
}
.pv-icon__symbol--v2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 191, 255, 0.5);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAABlCAYAAABUfC3PAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtVJREFUeNrsm/9R4kAUgFfH/00HhxWAFZirQEqQCg47wAq0gwsdYAXGDkIHuQ6ggpjVZXzgJnPHbdyX8H0zb3TYcQ378fY3Z1VVGdDFOU2AFEAKUgApSAGkIAWQAkhBCiAFKYAUpABSAClIAaQgBZCCFLAkdUzrSGM+xAUS3gXsYizKZnVkMR7q7MTufbVJkDy7jCFTIkp4rSMXEZULJMSXMDQpg5DQdymDlNA3KX8rwbJ0s6W859mvbvZ1TCa8uNd+DkGIhkw5ie5IuxQkKJLyhARdUuZ1/EKCLikrlyUFEvRIKU3EfaQ+wtY9UgAprOijsXsDazfT22EnFhukxMHO5m48r6/drK93YobQfU3duucQMgX6tU4JRWrC3DIp3YJ2Q6Ycz50bwMcB69w6MXONcrRLsY322GH9KicD58oz5FF8sq/th+gf4tr93Y6ZKFu618YuY5RN8utMURijOjbVB/bn5Mh6JtUntp5ElGWibKHp/WuVkosGm/5nXYuGxreCClE2QkpzpKKhskB1lg3ZIjMp19IGGseUhRhH5oHrvDT7xwiFGF/srsCIgf4rI7Fl8hRwVrQSg/60QZgJ+CEYlBTZYFnAejfm88Tz1rOYfG4QhhTRKGvXWCFZHewO+Mp+1DFByj43ngYMRdEiJRe/I+VgPPE1YBdSEk8XtvU8B1IOxoAu2LZkQ0GmtDPpuM7EU560lJ3shqTt518UPMerifxFVC5O+KfPdF9isNVAgRR9UkqkfO3PyRRlUmIfOP1Bij4pKk4hNZ7R58Z/ue47uGJM8bOIOJ6VZIqubLnSIkXr4vHO7N9E6ZoHRVNy1fe+rJjf39RtpZreuOZtlqyO+47/hz1MU/fVP+17X/acfubWDyGxXePSKP2qRJ9u3aeGC95A9wVIQQogBSmAFKQAUgApSAGkIAWQghRACiAFKYAUpABSkAJIAaQgBZCCFAjFmwADAPvHVjSjrBSpAAAAAElFTkSuQmCC");
  transition: background-color 0.5s ease-in-out;
}
.pv-icon__label {
  margin-right: 8px;
  margin-left: 8px;
  width: auto;
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  word-break: keep-all;
  white-space: nowrap;
}
.pv-icon__label--v2 {
  color: rgba(0, 191, 255, 0.5);
}

@media screen and (max-width: 540px) {
  .pv-icon {
    height: 40px;
    margin-bottom: 20px;
  }
  .pv-icon__symbol {
    font-size: 14px;
    margin: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pv-icon__label {
    margin-right: 6px;
    margin-left: 6px;
    line-height: 14px;
    font-size: 12px;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .pv-icon {
    height: 40px;
    margin-bottom: 20px;
  }
  .pv-icon__symbol {
    font-size: 14px;
    margin: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pv-icon__label {
    margin-right: 6px;
    margin-left: 6px;
    line-height: 14px;
    font-size: 12px;
  }
}
.player__pushoverlay {
  z-index: 99999991;
  cursor: normal;
  text-transform: uppercase;
  margin-bottom: -50px;
  margin-left: -50px;
}

.player__pushoverlay.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.player__pushoverlay.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.player__pushoverlay.player__pushoverlay--near {
  margin-bottom: -75px;
  margin-left: -75px;
}

.player__pushoverlay .push__label {
	color: #A9A9A9;
	
}

.player {
  position: relative;
}

.react-player > div {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.react-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-overlay {
  position: absolute;
  top: 0;
  bottom: 42px;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
  background: black;
}
.suggested-video-overlay {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  background-color: #3b3c41;
  position: absolute;
  left: 0;
  right: 0;
  height: 175px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition-delay: 0.1s;
  bottom: 42px;
}

.suggested-video-overlay.has-banner {
  bottom:145px;
}

.suggested-video-overlay > div:first-of-type {
  margin-left: 70px;
}
.suggested-video-overlay > div:nth-of-type(2) {
  margin-left: 20%;
}
.suggested-video-overlay a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.suggested-video-overlay a .link-text {
  border-bottom: 1px solid white;
}

.suggested-video-overlay i {
  font-size: 70px;
  margin-right: 30px;
}
.suggested-video-overlay h6 {
  flex: none;
  flex-basis: 80%;
  padding: 30px 0 0px 70px;
  margin: 0;
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
}
.suggested-video-overlay.visible {
  z-index: 999;
  pointer-events: all;
  opacity: 1;
  transition-delay: 0s;
}
.player-placeholder {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: black;
}
.buttons-transparent-video-overlay {
  z-index: 999;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  height: 60px;
}

@media screen and (max-width: 540px) {
  .suggested-video-overlay {
    display: none;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .suggested-video-overlay {
    bottom: 0px;
  }
  
  .suggested-video-overlay.has-banner {
    bottom:103px;
  }

  .suggested-video-overlay > div:first-of-type {
    margin-left: 20px;
  }
  .suggested-video-overlay > div:nth-of-type(2) {
    margin-left: 20%;
  }
  
  .suggested-video-overlay i {
    font-size: 70px;
    margin-right: 30px;
  }
  .suggested-video-overlay h6 {
    flex-basis: 90%;
    padding: 30px 0 0px 20px;
  }
}
.ads-badge-container {  
  display: flex;
  justify-content: space-around;
  align-items: flex-end;   
  padding: 6px 6px;  
  z-index: 9999999;
}
.banner-label {
  margin: 0;
  font-size: 19px;
  font-weight: normal;
  color: white;
  padding: 6px;
}
.map__badge {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map__badge-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map__badge-text {
  margin-bottom: 5px;
  color: white;
  z-index: 100;
}

.map__badge-img {
  z-index: 100;
}
.map {
    width: 100%;
    height: 620px;
    position: absolute;
    top: 63px;
    background-color: #3B3C41;
    z-index: 99999999;
}

@media screen and (max-width: 540px) {
    .map {
        height: auto;
        position: fixed;
        top: 57px;
        bottom: 0;
    }
}
.menu__badge {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  text-align: center;
  transition: background-color .5s ease-in-out;
  cursor: pointer;
}

.menu__badge-text {
  line-height: 32px;
  color: white;
}

.menu__badge-title {
  padding-left: 15px;
  color: white;
  cursor: pointer;
}
.menu__block {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3b3c41;
  z-index: 99999999;
  overflow: auto;
}

.menu__list-category {
  margin-right: 20px;
}

.menu__title {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
  font-size: 14px;
}

.menu__list-category li {
  display: flex;
  align-items: center;
  margin: 12px 0;
  list-style: none;
}

.menu__list-category li:hover .menu__badge-title {
  color: rgba(255, 255, 255, 0.4);
}

.menu__list-category ul {
  padding-left: 15px;
}

@media screen and (max-width: 540px) {
  .menu__block {
    height: auto;
    position: fixed;
    top: 57px;
    bottom: 0;
    overflow: auto;
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
}

.app-title {
  text-transform: uppercase;
  color: rgba(255, 255, 255, .5);
  margin-bottom: 5px;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
}

.resort-title {
  margin: 0;
  font-size: 19px;
  font-weight: normal;
  color: white;
}

.frame__menu {
  margin: 0;
}

.frame__menu li {
  display: inline-block;
  margin-left: 15px;
  border-radius: 50%;
  list-style: none;
  box-sizing: border-box;
}

.frame__menu a {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  color: white;
  border: 2px solid transparent;
}

.frame__menu a:hover {
  border-color: white;
}

.frame__menu a.active {
  border-color: white;
}

.frame__menu a i {
  font-size: 18px;
  line-height: 42px;
}

@media screen and (max-width: 540px) {
  .resort-title {
    font-size: 12px;
  }

  .frame__menu a {
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
  }

  .frame__menu a i {
    font-size: 14px;
    line-height: 30px;
  }
}


@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .resort-title {
    font-size: 12px;
  }

  .frame__menu {
    padding:0;
  }

  .frame__menu a {
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
  }

  .frame__menu a i {
    font-size: 14px;
    line-height: 30px;
  }

  .top-bar {
    margin-left: 50%;
    padding: 6px;
  } 
}
@font-face {
  font-family: "cronos_semibold";
  src: url("/fonts/cronos_semibold.eot?#iefix");
  src: url("/fonts/cronos_semiboldc.eot?#iefix") format("eot"), url("/fonts/cronos_semibold.woff") format("woff"), url("/fonts/cronos_semibold.ttf") format("truetype"), url("/fonts/cronos_semibold.svg#cronos_semibold") format("svg");
}

/* cairo-regular - latin */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/cairo-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/cairo-v18-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/cairo-v18-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/cairo-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/cairo-v18-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/cairo-v18-latin-regular.svg#Cairo") format("svg"); /* Legacy iOS */
}

/* cairo-700 - latin */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/cairo-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/cairo-v18-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/cairo-v18-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/cairo-v18-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/cairo-v18-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/cairo-v18-latin-700.svg#Cairo") format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/montserrat-v24-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/montserrat-v24-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/montserrat-v24-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/montserrat-v24-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/montserrat-v24-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/montserrat-v24-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/montserrat-v24-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/montserrat-v24-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/montserrat-v24-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("/fonts/montserrat-v24-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/montserrat-v24-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */ url("/fonts/montserrat-v24-latin-700.svg#Montserrat") format("svg"); /* Legacy iOS */
}

body,
html {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fs-bg {
  background-color: #eeeeee;
}

.font-cairo {
  font-family: "Cairo", sans-serif;
}

.font-cronos {
  font-family: cronos_semibold, sans-serif;
}

.app {
  min-width: 300px;
  width: 100vw;
  height: 68.44vw; /* height:width ratio = 10.95/16 = .684375  */
  max-height: 100vh;
  max-width: 146vh; /* 16/10.95 = 1.6 */
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  /* vertical center */
  left: 0;
  right: 0;
  /* horizontal center */
}

.app.mode-fs {
  /*
   * max-width: 100%;
  max-height: 100vh;
*/
}

.app-content {
  border-radius: 4px;
  background-color: #3b3c41;
  position: relative;
}

.footer {
  font-size: 10px;
  text-align: right;
  padding: 5px;
  margin: 0;
}

.footer a {
  text-decoration: none;
  color: #595959;
}

.d-none {
  display: none !important;
}

.dark .resort-title,
.dark svg .dark body {
}
.dark svg circle {
  stroke: #595959;
}
.dark svg text {
  fill: #595959;
}
.dark svg path {
  fill: #595959;
}

