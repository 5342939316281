.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
}

.app-title {
  text-transform: uppercase;
  color: rgba(255, 255, 255, .5);
  margin-bottom: 5px;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
}

.resort-title {
  margin: 0;
  font-size: 19px;
  font-weight: normal;
  color: white;
}

.frame__menu {
  margin: 0;
}

.frame__menu li {
  display: inline-block;
  margin-left: 15px;
  border-radius: 50%;
  list-style: none;
  box-sizing: border-box;
}

.frame__menu a {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  color: white;
  border: 2px solid transparent;
}

.frame__menu a:hover {
  border-color: white;
}

.frame__menu a.active {
  border-color: white;
}

.frame__menu a i {
  font-size: 18px;
  line-height: 42px;
}

@media screen and (max-width: 540px) {
  .resort-title {
    font-size: 12px;
  }

  .frame__menu a {
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
  }

  .frame__menu a i {
    font-size: 14px;
    line-height: 30px;
  }
}


@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .resort-title {
    font-size: 12px;
  }

  .frame__menu {
    padding:0;
  }

  .frame__menu a {
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
  }

  .frame__menu a i {
    font-size: 14px;
    line-height: 30px;
  }

  .top-bar {
    margin-left: 50%;
    padding: 6px;
  } 
}